
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Spinner',
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    }
  }
});
