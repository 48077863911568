/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { AirwayBillNumber } from '../../model/vista';
// @ts-ignore
import { BillOfLadingNumber } from '../../model/vista';
// @ts-ignore
import { Delivery } from '../../model/vista';
// @ts-ignore
import { DeliveryCount } from '../../model/vista';
// @ts-ignore
import { FlightNumber } from '../../model/vista';
// @ts-ignore
import { IncotermResult } from '../../model/vista';
// @ts-ignore
import { MasterAirwayBillNumber } from '../../model/vista';
// @ts-ignore
import { Pageable } from '../../model/vista';
// @ts-ignore
import { SapDocumentNumber } from '../../model/vista';
/**
 * DeliveriesApi - axios parameter creator
 * @export
 */
export const DeliveriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return related bill of lading numbers based on inputted bill of lading number
         * @summary Returns a list of related bill of lading numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billOfLadings: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('billOfLadings', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('billOfLadings', 'pageable', pageable)
            const localVarPath = `/deliveries/billofladings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the count of deliveries (filters not yet considered)
         * @summary Counts the number of deliveries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDeliveries1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deliveries/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a list of deliveries
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveries: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('deliveries', 'pageable', pageable)
            const localVarPath = `/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related delivery number based on inputted delivery number
         * @summary Returns a list of related delivery Numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryNumbers: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('deliveryNumbers', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('deliveryNumbers', 'pageable', pageable)
            const localVarPath = `/deliveries/numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related flight numbers based on inputted bill of lading number
         * @summary Returns a list of related flight numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flightNumbers: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('flightNumbers', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('flightNumbers', 'pageable', pageable)
            const localVarPath = `/deliveries/flightNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related house airway bill numbers based on inputted house airway bill number
         * @summary Returns a list of related house airway bill numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        houseAirWayBills: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('houseAirWayBills', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('houseAirWayBills', 'pageable', pageable)
            const localVarPath = `/deliveries/houseairwaybill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related incoterms based on inputted incoterm codes
         * @summary Returns a list of related incoterms
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incotermByCodes: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('incotermByCodes', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('incotermByCodes', 'pageable', pageable)
            const localVarPath = `/deliveries/incotermcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related incoterms based on inputted incoterm locations
         * @summary Returns a list of related incoterms
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incotermByLocations: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('incotermByLocations', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('incotermByLocations', 'pageable', pageable)
            const localVarPath = `/deliveries/incotermlocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related master airway bill numbers based on inputted master airway bill number
         * @summary Returns a list of related master airway bill numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterAirWaybills: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('masterAirWaybills', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('masterAirWaybills', 'pageable', pageable)
            const localVarPath = `/deliveries/masterairwaybill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveriesApi - functional programming interface
 * @export
 */
export const DeliveriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Return related bill of lading numbers based on inputted bill of lading number
         * @summary Returns a list of related bill of lading numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billOfLadings(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillOfLadingNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billOfLadings(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.billOfLadings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the count of deliveries (filters not yet considered)
         * @summary Counts the number of deliveries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDeliveries1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDeliveries1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.countDeliveries1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a list of deliveries
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveries(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Delivery>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveries(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.deliveries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related delivery number based on inputted delivery number
         * @summary Returns a list of related delivery Numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SapDocumentNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryNumbers(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.deliveryNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related flight numbers based on inputted bill of lading number
         * @summary Returns a list of related flight numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flightNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FlightNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flightNumbers(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.flightNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related house airway bill numbers based on inputted house airway bill number
         * @summary Returns a list of related house airway bill numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async houseAirWayBills(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AirwayBillNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.houseAirWayBills(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.houseAirWayBills']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related incoterms based on inputted incoterm codes
         * @summary Returns a list of related incoterms
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incotermByCodes(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncotermResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incotermByCodes(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.incotermByCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related incoterms based on inputted incoterm locations
         * @summary Returns a list of related incoterms
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incotermByLocations(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncotermResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incotermByLocations(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.incotermByLocations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related master airway bill numbers based on inputted master airway bill number
         * @summary Returns a list of related master airway bill numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterAirWaybills(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MasterAirwayBillNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterAirWaybills(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveriesApi.masterAirWaybills']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliveriesApi - factory interface
 * @export
 */
export const DeliveriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveriesApiFp(configuration)
    return {
        /**
         * Return related bill of lading numbers based on inputted bill of lading number
         * @summary Returns a list of related bill of lading numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billOfLadings(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<BillOfLadingNumber>> {
            return localVarFp.billOfLadings(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the count of deliveries (filters not yet considered)
         * @summary Counts the number of deliveries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDeliveries1(options?: any): AxiosPromise<DeliveryCount> {
            return localVarFp.countDeliveries1(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a summary of delivery information
         * @summary Returns a list of deliveries
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveries(pageable: Pageable, options?: any): AxiosPromise<Array<Delivery>> {
            return localVarFp.deliveries(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related delivery number based on inputted delivery number
         * @summary Returns a list of related delivery Numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryNumbers(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<SapDocumentNumber>> {
            return localVarFp.deliveryNumbers(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related flight numbers based on inputted bill of lading number
         * @summary Returns a list of related flight numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flightNumbers(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<FlightNumber>> {
            return localVarFp.flightNumbers(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related house airway bill numbers based on inputted house airway bill number
         * @summary Returns a list of related house airway bill numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        houseAirWayBills(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<AirwayBillNumber>> {
            return localVarFp.houseAirWayBills(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related incoterms based on inputted incoterm codes
         * @summary Returns a list of related incoterms
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incotermByCodes(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<IncotermResult>> {
            return localVarFp.incotermByCodes(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related incoterms based on inputted incoterm locations
         * @summary Returns a list of related incoterms
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incotermByLocations(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<IncotermResult>> {
            return localVarFp.incotermByLocations(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related master airway bill numbers based on inputted master airway bill number
         * @summary Returns a list of related master airway bill numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterAirWaybills(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<MasterAirwayBillNumber>> {
            return localVarFp.masterAirWaybills(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveriesApi - object-oriented interface
 * @export
 * @class DeliveriesApi
 * @extends {BaseAPI}
 */
export class DeliveriesApi extends BaseAPI {
    /**
     * Return related bill of lading numbers based on inputted bill of lading number
     * @summary Returns a list of related bill of lading numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public billOfLadings(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).billOfLadings(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the count of deliveries (filters not yet considered)
     * @summary Counts the number of deliveries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public countDeliveries1(options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).countDeliveries1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a summary of delivery information
     * @summary Returns a list of deliveries
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public deliveries(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).deliveries(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related delivery number based on inputted delivery number
     * @summary Returns a list of related delivery Numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public deliveryNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).deliveryNumbers(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related flight numbers based on inputted bill of lading number
     * @summary Returns a list of related flight numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public flightNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).flightNumbers(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related house airway bill numbers based on inputted house airway bill number
     * @summary Returns a list of related house airway bill numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public houseAirWayBills(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).houseAirWayBills(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related incoterms based on inputted incoterm codes
     * @summary Returns a list of related incoterms
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public incotermByCodes(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).incotermByCodes(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related incoterms based on inputted incoterm locations
     * @summary Returns a list of related incoterms
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public incotermByLocations(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).incotermByLocations(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related master airway bill numbers based on inputted master airway bill number
     * @summary Returns a list of related master airway bill numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public masterAirWaybills(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).masterAirWaybills(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}

