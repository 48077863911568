/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { Pageable } from '../../model/vista';
// @ts-ignore
import { SalesOrder } from '../../model/vista';
// @ts-ignore
import { SapDocumentNumber } from '../../model/vista';
/**
 * SalesOrdersApi - axios parameter creator
 * @export
 */
export const SalesOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Temporary return a sales order from the database
         * @summary Returns a sales order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findById', 'id', id)
            const localVarPath = `/salesorders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related sales order ids based on inputted salesorder number
         * @summary Return related sales order numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderNumbers: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('getOrderNumbers', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getOrderNumbers', 'pageable', pageable)
            const localVarPath = `/salesorders/numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesOrdersApi - functional programming interface
 * @export
 */
export const SalesOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Temporary return a sales order from the database
         * @summary Returns a sales order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesOrdersApi.findById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related sales order ids based on inputted salesorder number
         * @summary Return related sales order numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SapDocumentNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderNumbers(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesOrdersApi.getOrderNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SalesOrdersApi - factory interface
 * @export
 */
export const SalesOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesOrdersApiFp(configuration)
    return {
        /**
         * Temporary return a sales order from the database
         * @summary Returns a sales order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById(id: string, options?: any): AxiosPromise<SalesOrder> {
            return localVarFp.findById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related sales order ids based on inputted salesorder number
         * @summary Return related sales order numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderNumbers(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<SapDocumentNumber>> {
            return localVarFp.getOrderNumbers(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesOrdersApi - object-oriented interface
 * @export
 * @class SalesOrdersApi
 * @extends {BaseAPI}
 */
export class SalesOrdersApi extends BaseAPI {
    /**
     * Temporary return a sales order from the database
     * @summary Returns a sales order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public findById(id: string, options?: RawAxiosRequestConfig) {
        return SalesOrdersApiFp(this.configuration).findById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related sales order ids based on inputted salesorder number
     * @summary Return related sales order numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public getOrderNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return SalesOrdersApiFp(this.configuration).getOrderNumbers(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}

