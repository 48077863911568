/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { DQNewStageEvent } from '../../model/vista';
/**
 * DQApi - axios parameter creator
 * @export
 */
export const DQApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add the ETA/ATA for all shipments in the list and return them
         * @param {Array<DQNewStageEvent>} dQNewStageEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewStageEvents: async (dQNewStageEvent: Array<DQNewStageEvent>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dQNewStageEvent' is not null or undefined
            assertParamExists('addNewStageEvents', 'dQNewStageEvent', dQNewStageEvent)
            const localVarPath = `/logistictracker/laststage/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dQNewStageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DQApi - functional programming interface
 * @export
 */
export const DQApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DQApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add the ETA/ATA for all shipments in the list and return them
         * @param {Array<DQNewStageEvent>} dQNewStageEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewStageEvents(dQNewStageEvent: Array<DQNewStageEvent>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewStageEvents(dQNewStageEvent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DQApi.addNewStageEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DQApi - factory interface
 * @export
 */
export const DQApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DQApiFp(configuration)
    return {
        /**
         * 
         * @summary Add the ETA/ATA for all shipments in the list and return them
         * @param {Array<DQNewStageEvent>} dQNewStageEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewStageEvents(dQNewStageEvent: Array<DQNewStageEvent>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.addNewStageEvents(dQNewStageEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DQApi - object-oriented interface
 * @export
 * @class DQApi
 * @extends {BaseAPI}
 */
export class DQApi extends BaseAPI {
    /**
     * 
     * @summary Add the ETA/ATA for all shipments in the list and return them
     * @param {Array<DQNewStageEvent>} dQNewStageEvent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DQApi
     */
    public addNewStageEvents(dQNewStageEvent: Array<DQNewStageEvent>, options?: RawAxiosRequestConfig) {
        return DQApiFp(this.configuration).addNewStageEvents(dQNewStageEvent, options).then((request) => request(this.axios, this.basePath));
    }
}

