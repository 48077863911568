/* tslint:disable */
/* eslint-disable */
/**
 * Vista User API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.12.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import type { Filter } from '../../model/vista';
// @ts-ignore
import type { FollowedDelivery } from '../../model/vista';
// @ts-ignore
import type { OutOfOffice } from '../../model/vista';
// @ts-ignore
import type { User } from '../../model/vista';
// @ts-ignore
import type { UserLocale } from '../../model/vista';
// @ts-ignore
import type { UserNotification } from '../../model/vista';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return information about the logged user.
     * @summary Return user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Set whether the delivery should be tracked or not.
     * @summary Follow delivery
     * @param {FollowedDelivery} followedDelivery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    followDelivery: async (followedDelivery: FollowedDelivery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'followedDelivery' is not null or undefined
      assertParamExists('followDelivery', 'followedDelivery', followedDelivery);
      const localVarPath = `/users/me/followed-deliveries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(followedDelivery, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the list of followed deliveries for the logged user.
     * @summary List of tracked deliveries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me/followed-deliveries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Return information about the logged user or create a new one when the user does not exist.
     * @summary Return user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrCreateUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Return information about the user
     * @summary Return user information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUser', 'id', id);
      const localVarPath = `/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Return roles assigned to a user, by cn.
     * @summary Return user roles
     * @param {string} cn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserRoles: async (cn: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cn' is not null or undefined
      assertParamExists('getUserRoles', 'cn', cn);
      const localVarPath = `/users/{cn}/roles`.replace(`{${'cn'}}`, encodeURIComponent(String(cn)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the users that are following a particular delivery
     * @summary Return the users following a delivery
     * @param {string} followingDelivery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersFollowingDelivery: async (followingDelivery: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'followingDelivery' is not null or undefined
      assertParamExists('getUsersFollowingDelivery', 'followingDelivery', followingDelivery);
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      if (followingDelivery !== undefined) {
        localVarQueryParameter['followingDelivery'] = followingDelivery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create a new user.
     * @summary Create new user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Restore default values to the list of columns to be displayed.
     * @summary Reset list of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetTableColumns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me/settings/theme/table-columns/reset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Store the last applied field for the logged user.
     * @summary Save current applied filter
     * @param {Filter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAppliedFilter: async (filter: Filter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('saveAppliedFilter', 'filter', filter);
      const localVarPath = `/users/me/settings/applied-filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Store all information related to notifications.
     * @summary Save notifications
     * @param {UserNotification} userNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNotifications: async (userNotification: UserNotification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userNotification' is not null or undefined
      assertParamExists('saveNotifications', 'userNotification', userNotification);
      const localVarPath = `/users/me/settings/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userNotification, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Store the out of office policy for the logged user.
     * @summary Save out of office policy
     * @param {OutOfOffice} outOfOffice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOutOfOffice: async (outOfOffice: OutOfOffice, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'outOfOffice' is not null or undefined
      assertParamExists('saveOutOfOffice', 'outOfOffice', outOfOffice);
      const localVarPath = `/users/me/settings/out-of-office`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(outOfOffice, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Store list of columns to be displayed.
     * @summary Store list of columns
     * @param {Array<any>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveTableColumns: async (requestBody: Array<any>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('saveTableColumns', 'requestBody', requestBody);
      const localVarPath = `/users/me/settings/theme/table-columns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Store the theme details for the logged user.
     * @summary Save information about the theme
     * @param {any} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveThemeData: async (body: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('saveThemeData', 'body', body);
      const localVarPath = `/users/me/settings/theme/data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Store the user locale settings.
     * @summary Save User Locale
     * @param {UserLocale} userLocale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveUserLocale: async (userLocale: UserLocale, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userLocale' is not null or undefined
      assertParamExists('saveUserLocale', 'userLocale', userLocale);
      const localVarPath = `/users/me/settings/locale`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userLocale, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Set whether the delivery should be tracked or not.
     * @summary Unfollow delivery
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unfollowDelivery: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('unfollowDelivery', 'id', id);
      const localVarPath = `/users/me/followed-deliveries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     * Return information about the logged user.
     * @summary Return user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async currentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.currentUser(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.currentUser']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Set whether the delivery should be tracked or not.
     * @summary Follow delivery
     * @param {FollowedDelivery} followedDelivery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async followDelivery(
      followedDelivery: FollowedDelivery,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.followDelivery(followedDelivery, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.followDelivery']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the list of followed deliveries for the logged user.
     * @summary List of tracked deliveries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeliveries(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveries(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.getDeliveries']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return information about the logged user or create a new one when the user does not exist.
     * @summary Return user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrCreateUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrCreateUser(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.getOrCreateUser']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return information about the user
     * @summary Return user information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.getUser']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return roles assigned to a user, by cn.
     * @summary Return user roles
     * @param {string} cn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserRoles(
      cn: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRoles(cn, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.getUserRoles']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets the users that are following a particular delivery
     * @summary Return the users following a delivery
     * @param {string} followingDelivery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersFollowingDelivery(
      followingDelivery: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersFollowingDelivery(followingDelivery, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.getUsersFollowingDelivery']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create a new user.
     * @summary Create new user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newUser(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.newUser']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Restore default values to the list of columns to be displayed.
     * @summary Reset list of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetTableColumns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetTableColumns(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.resetTableColumns']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Store the last applied field for the logged user.
     * @summary Save current applied filter
     * @param {Filter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveAppliedFilter(
      filter: Filter,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveAppliedFilter(filter, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.saveAppliedFilter']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Store all information related to notifications.
     * @summary Save notifications
     * @param {UserNotification} userNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveNotifications(
      userNotification: UserNotification,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveNotifications(userNotification, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.saveNotifications']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Store the out of office policy for the logged user.
     * @summary Save out of office policy
     * @param {OutOfOffice} outOfOffice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveOutOfOffice(
      outOfOffice: OutOfOffice,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveOutOfOffice(outOfOffice, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.saveOutOfOffice']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Store list of columns to be displayed.
     * @summary Store list of columns
     * @param {Array<any>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveTableColumns(
      requestBody: Array<any>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveTableColumns(requestBody, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.saveTableColumns']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Store the theme details for the logged user.
     * @summary Save information about the theme
     * @param {any} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveThemeData(
      body: any,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveThemeData(body, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.saveThemeData']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Store the user locale settings.
     * @summary Save User Locale
     * @param {UserLocale} userLocale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveUserLocale(
      userLocale: UserLocale,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserLocale(userLocale, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.saveUserLocale']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Set whether the delivery should be tracked or not.
     * @summary Unfollow delivery
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unfollowDelivery(
      id: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unfollowDelivery(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['UserApi.unfollowDelivery']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    }
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     * Return information about the logged user.
     * @summary Return user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentUser(options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.currentUser(options).then((request) => request(axios, basePath));
    },
    /**
     * Set whether the delivery should be tracked or not.
     * @summary Follow delivery
     * @param {FollowedDelivery} followedDelivery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    followDelivery(followedDelivery: FollowedDelivery, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
      return localVarFp.followDelivery(followedDelivery, options).then((request) => request(axios, basePath));
    },
    /**
     * Get the list of followed deliveries for the logged user.
     * @summary List of tracked deliveries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveries(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
      return localVarFp.getDeliveries(options).then((request) => request(axios, basePath));
    },
    /**
     * Return information about the logged user or create a new one when the user does not exist.
     * @summary Return user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrCreateUser(options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.getOrCreateUser(options).then((request) => request(axios, basePath));
    },
    /**
     * Return information about the user
     * @summary Return user information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Return roles assigned to a user, by cn.
     * @summary Return user roles
     * @param {string} cn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserRoles(cn: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
      return localVarFp.getUserRoles(cn, options).then((request) => request(axios, basePath));
    },
    /**
     * Gets the users that are following a particular delivery
     * @summary Return the users following a delivery
     * @param {string} followingDelivery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersFollowingDelivery(followingDelivery: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<User>> {
      return localVarFp.getUsersFollowingDelivery(followingDelivery, options).then((request) => request(axios, basePath));
    },
    /**
     * Create a new user.
     * @summary Create new user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUser(options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.newUser(options).then((request) => request(axios, basePath));
    },
    /**
     * Restore default values to the list of columns to be displayed.
     * @summary Reset list of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetTableColumns(options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.resetTableColumns(options).then((request) => request(axios, basePath));
    },
    /**
     * Store the last applied field for the logged user.
     * @summary Save current applied filter
     * @param {Filter} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAppliedFilter(filter: Filter, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.saveAppliedFilter(filter, options).then((request) => request(axios, basePath));
    },
    /**
     * Store all information related to notifications.
     * @summary Save notifications
     * @param {UserNotification} userNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNotifications(userNotification: UserNotification, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.saveNotifications(userNotification, options).then((request) => request(axios, basePath));
    },
    /**
     * Store the out of office policy for the logged user.
     * @summary Save out of office policy
     * @param {OutOfOffice} outOfOffice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOutOfOffice(outOfOffice: OutOfOffice, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.saveOutOfOffice(outOfOffice, options).then((request) => request(axios, basePath));
    },
    /**
     * Store list of columns to be displayed.
     * @summary Store list of columns
     * @param {Array<any>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveTableColumns(requestBody: Array<any>, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.saveTableColumns(requestBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Store the theme details for the logged user.
     * @summary Save information about the theme
     * @param {any} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveThemeData(body: any, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.saveThemeData(body, options).then((request) => request(axios, basePath));
    },
    /**
     * Store the user locale settings.
     * @summary Save User Locale
     * @param {UserLocale} userLocale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveUserLocale(userLocale: UserLocale, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.saveUserLocale(userLocale, options).then((request) => request(axios, basePath));
    },
    /**
     * Set whether the delivery should be tracked or not.
     * @summary Unfollow delivery
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unfollowDelivery(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
      return localVarFp.unfollowDelivery(id, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Return information about the logged user.
   * @summary Return user information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public currentUser(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .currentUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Set whether the delivery should be tracked or not.
   * @summary Follow delivery
   * @param {FollowedDelivery} followedDelivery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public followDelivery(followedDelivery: FollowedDelivery, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .followDelivery(followedDelivery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of followed deliveries for the logged user.
   * @summary List of tracked deliveries
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getDeliveries(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getDeliveries(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return information about the logged user or create a new one when the user does not exist.
   * @summary Return user information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getOrCreateUser(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getOrCreateUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return information about the user
   * @summary Return user information
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(id: string, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getUser(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return roles assigned to a user, by cn.
   * @summary Return user roles
   * @param {string} cn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserRoles(cn: string, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getUserRoles(cn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the users that are following a particular delivery
   * @summary Return the users following a delivery
   * @param {string} followingDelivery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUsersFollowingDelivery(followingDelivery: string, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getUsersFollowingDelivery(followingDelivery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new user.
   * @summary Create new user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public newUser(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .newUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Restore default values to the list of columns to be displayed.
   * @summary Reset list of columns
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public resetTableColumns(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .resetTableColumns(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store the last applied field for the logged user.
   * @summary Save current applied filter
   * @param {Filter} filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public saveAppliedFilter(filter: Filter, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .saveAppliedFilter(filter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store all information related to notifications.
   * @summary Save notifications
   * @param {UserNotification} userNotification
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public saveNotifications(userNotification: UserNotification, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .saveNotifications(userNotification, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store the out of office policy for the logged user.
   * @summary Save out of office policy
   * @param {OutOfOffice} outOfOffice
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public saveOutOfOffice(outOfOffice: OutOfOffice, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .saveOutOfOffice(outOfOffice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store list of columns to be displayed.
   * @summary Store list of columns
   * @param {Array<any>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public saveTableColumns(requestBody: Array<any>, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .saveTableColumns(requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store the theme details for the logged user.
   * @summary Save information about the theme
   * @param {any} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public saveThemeData(body: any, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .saveThemeData(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store the user locale settings.
   * @summary Save User Locale
   * @param {UserLocale} userLocale
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public saveUserLocale(userLocale: UserLocale, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .saveUserLocale(userLocale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Set whether the delivery should be tracked or not.
   * @summary Unfollow delivery
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public unfollowDelivery(id: string, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .unfollowDelivery(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
