/* eslint-disable */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default {
  install: (app, options) => {
    const config = options.appInsightsConfig || {};
    config.instrumentationKey = config.instrumentationKey || options.id;

    if (options.appInsights) {
      app.config.globalProperties.$appInsights = options.appInsights;
    } else {
      const appInsights = new ApplicationInsights({ config });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
      app.config.globalProperties.$appInsights = appInsights;
    }
  }
};
