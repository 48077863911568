import { filterApi, searchApi, userApi } from '@/services/api';
import { FilterSelection, SavedFilter } from '@/typings';
import FiltersUtils from '@/scripts/filtersUtils';
import { FilterType } from '@/typings/enums';
import { Filter } from '@/services/model/user';
import { AutoCompleteEndpoints } from '@/constants';

export const state = () => ({
  savedFilters: [] as SavedFilter[],
  selectedConditions: {
    [FilterType.TIME]: [],
    [FilterType.ORIGIN]: [],
    [FilterType.DESTINATION]: [],
    [FilterType.STATE]: [],
    [FilterType.CARRIERS]: [],
    [FilterType.STATUS]: [],
    [FilterType.PARTNER_ID]: [],
    [FilterType.PRIMARY_MODE]: [],
    [FilterType.PRODUCT]: [],
    [FilterType.BUSINESS_UNIT]: [],
    [FilterType.ADDITIONAL_FIELDS]: [],
    [FilterType.DQ_FILTER]: []
  } as Record<FilterType, FilterSelection[]>
});

const mutations = {
  // clear and replace all conditions with the new dataset
  saveFiltersData(state, data) {
    // protection in case conditions come as null or undeclared from the backend
    if (!data.filters) {
      data.filters = [];
    }

    // transform filter to frontend format
    const filters = [] as SavedFilter[];
    for (let i = 0; i < data.length; i++) {
      filters.push({
        filterId: data[i].filterId,
        name: data[i].name ? data[i].name : '(no name!)',
        selected: false,
        conditions: FiltersUtils.transformSavedToObject(data[i].conditions),
        alert: data[i].alert
      });
    }
    state.savedFilters = filters;
  },
  /// add one filter into the existing dataset
  addFilter(state, data) {
    state.savedFilters.push({
      filterId: data.filterId,
      name: data.name ? data.name : '(no name!)',
      selected: false,
      conditions: FiltersUtils.transformSavedToObject(data.conditions),
      alert: data.alert
    });
  },
  deleteFilter(state, id) {
    state.savedFilters = state.savedFilters.filter((f) => f.filterId !== id);
  },
  updateConditions(state, conditions: Record<FilterType, FilterSelection[]>) {
    state.selectedConditions = conditions;
  },
  loadPreviousFilterConditions(state, data) {
    if (data && data.filterId) {
      // if a filter was selected assign it here
      state.savedFilters.forEach((filter) => {
        filter.selected = filter.filterId === data.filterId;
      });
    }
    // assign saved conditions
    if (data && data.conditions) {
      state.selectedConditions = FiltersUtils.transformSavedToObject(data.conditions);
    }
  }
};

const actions = {
  //get filters list and update store - for refresh purposes
  GET_FILTERS: async ({ commit }) => {
    return await filterApi
      .getFilters()
      .then((resp) => {
        if (resp && resp.data) {
          commit('saveFiltersData', resp.data);
        }
        return resp;
      })
      .catch((error) => {
        console.log('Error retrieving filters list' + error);
        return false;
      });
  },

  //create a new filter
  ADD_FILTER: async ({ commit, dispatch }, filterObj) => {
    const filter = await filterApi
      .saveFilter(filterObj.data)
      .then((resp) => {
        if (filterObj.alert) {
          // if alert switch was on, we trigger second call here
          const id = resp.data!.alert!.alertId;
          dispatch('alerts/SET_STATUS', { id, status: true }, { root: true }); // silent error
        }
        // refresh local filters to include new item and id.
        commit('addFilter', resp.data);
        return resp.data;
      })
      .catch((error) => {
        console.log('Error creating filter ' + error);
        return false;
      });
    return filter;
  },

  // save current conditions
  SAVE_CURRENT_CONDITIONS: async ({ commit }, dataObj) => {
    // first push to store to keep it locally for 'on the fly' changes
    commit('updateConditions', dataObj.conditions);

    const appliedFilter: Filter = {
      filterId: dataObj.filter ? dataObj.filter.filterId : null,
      name: dataObj.filter ? dataObj.filter.name : null,
      conditions: FiltersUtils.createSaveObject(dataObj.conditions)
    };
    await userApi.saveAppliedFilter(appliedFilter);
    return true;
  },

  //edit a filter
  EDIT_FILTER: async ({ commit }, filterObj: Filter) => {
    return await filterApi
      .modifyFilter(filterObj.filterId!, filterObj)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log('Error saving filter changes ' + error);
        return false;
      });
  },

  //delete a filter
  DELETE_FILTER: async ({ commit }, id) => {
    return await filterApi
      .deleteFilter(id)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log('Error deleting filter ' + error);
        return false;
      });
  },

  // Autocomplete fields
  AUTOCOMPLETE: async ({ commit }, data) => {
    return await AutoCompleteEndpoints[data.field]
      .request(data.search)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log('Error retrieving autocomplete results ' + error);
        return false;
      });
  }
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
