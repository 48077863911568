/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { BookingNumber } from '../../model/vista';
// @ts-ignore
import { ContainerNumber } from '../../model/vista';
// @ts-ignore
import { Pageable } from '../../model/vista';
// @ts-ignore
import { SapDocumentNumber } from '../../model/vista';
// @ts-ignore
import { Vessel } from '../../model/vista';
// @ts-ignore
import { VoyageId } from '../../model/vista';
/**
 * ShipmentsApi - axios parameter creator
 * @export
 */
export const ShipmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return related booking numbers based on inputted booking number
         * @summary Returns a list of related booking numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingNumbers: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('bookingNumbers', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('bookingNumbers', 'pageable', pageable)
            const localVarPath = `/shipments/bookingnumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related container numbers based on inputted container number
         * @summary Returns a list of related container numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerNumbers: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('containerNumbers', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('containerNumbers', 'pageable', pageable)
            const localVarPath = `/shipments/containernumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related voyage ids based on inputted voyage id
         * @summary Returns a list of related voyage ids
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoyageIds: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('getVoyageIds', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getVoyageIds', 'pageable', pageable)
            const localVarPath = `/shipments/voyageids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related shipment number based on inputted shipment number
         * @summary Returns a list of related shipment Numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipmentNumbers: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('shipmentNumbers', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('shipmentNumbers', 'pageable', pageable)
            const localVarPath = `/shipments/numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related vessel IMO based on inputted IMO number
         * @summary Returns a list of related vessel IMO
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vesselsByIMO: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('vesselsByIMO', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('vesselsByIMO', 'pageable', pageable)
            const localVarPath = `/shipments/vesselimos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return related vessel names based on inputted name
         * @summary Returns a list of related vessel names
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vesselsByNames: async (searchTerm: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('vesselsByNames', 'searchTerm', searchTerm)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('vesselsByNames', 'pageable', pageable)
            const localVarPath = `/shipments/vesselnames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShipmentsApi - functional programming interface
 * @export
 */
export const ShipmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShipmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Return related booking numbers based on inputted booking number
         * @summary Returns a list of related booking numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingNumbers(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentsApi.bookingNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related container numbers based on inputted container number
         * @summary Returns a list of related container numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.containerNumbers(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentsApi.containerNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related voyage ids based on inputted voyage id
         * @summary Returns a list of related voyage ids
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoyageIds(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoyageId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoyageIds(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentsApi.getVoyageIds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related shipment number based on inputted shipment number
         * @summary Returns a list of related shipment Numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipmentNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SapDocumentNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentNumbers(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentsApi.shipmentNumbers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related vessel IMO based on inputted IMO number
         * @summary Returns a list of related vessel IMO
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vesselsByIMO(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vessel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vesselsByIMO(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentsApi.vesselsByIMO']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return related vessel names based on inputted name
         * @summary Returns a list of related vessel names
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vesselsByNames(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vessel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vesselsByNames(searchTerm, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentsApi.vesselsByNames']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShipmentsApi - factory interface
 * @export
 */
export const ShipmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShipmentsApiFp(configuration)
    return {
        /**
         * Return related booking numbers based on inputted booking number
         * @summary Returns a list of related booking numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingNumbers(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<BookingNumber>> {
            return localVarFp.bookingNumbers(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related container numbers based on inputted container number
         * @summary Returns a list of related container numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerNumbers(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<ContainerNumber>> {
            return localVarFp.containerNumbers(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related voyage ids based on inputted voyage id
         * @summary Returns a list of related voyage ids
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoyageIds(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<VoyageId>> {
            return localVarFp.getVoyageIds(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related shipment number based on inputted shipment number
         * @summary Returns a list of related shipment Numbers
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipmentNumbers(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<SapDocumentNumber>> {
            return localVarFp.shipmentNumbers(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related vessel IMO based on inputted IMO number
         * @summary Returns a list of related vessel IMO
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vesselsByIMO(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<Vessel>> {
            return localVarFp.vesselsByIMO(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Return related vessel names based on inputted name
         * @summary Returns a list of related vessel names
         * @param {string} searchTerm 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vesselsByNames(searchTerm: string, pageable: Pageable, options?: any): AxiosPromise<Array<Vessel>> {
            return localVarFp.vesselsByNames(searchTerm, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShipmentsApi - object-oriented interface
 * @export
 * @class ShipmentsApi
 * @extends {BaseAPI}
 */
export class ShipmentsApi extends BaseAPI {
    /**
     * Return related booking numbers based on inputted booking number
     * @summary Returns a list of related booking numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public bookingNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).bookingNumbers(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related container numbers based on inputted container number
     * @summary Returns a list of related container numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public containerNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).containerNumbers(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related voyage ids based on inputted voyage id
     * @summary Returns a list of related voyage ids
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public getVoyageIds(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).getVoyageIds(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related shipment number based on inputted shipment number
     * @summary Returns a list of related shipment Numbers
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public shipmentNumbers(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).shipmentNumbers(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related vessel IMO based on inputted IMO number
     * @summary Returns a list of related vessel IMO
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public vesselsByIMO(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).vesselsByIMO(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return related vessel names based on inputted name
     * @summary Returns a list of related vessel names
     * @param {string} searchTerm 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public vesselsByNames(searchTerm: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).vesselsByNames(searchTerm, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}

