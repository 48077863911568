import { FilterBackend, FilterSelection } from '@/typings';
import { FilterType, FilterDestinations, FilterOrigins, FilterTime, FilterAdditional } from '@/typings/enums';
import { Conditions, FilterLabels } from '@/constants';
import { AlertCondition, FilterCondition } from '@/services/model/user';

export default {
  // this function will create an array ready to send to backend based on the frontend filter selections
  createSaveObject(filters: Record<FilterType, FilterSelection[]>): FilterCondition[] {
    const saved: FilterCondition[] = [];

    // ----- TIME

    filters[FilterType.TIME].forEach((e) => {
      // date range is limited to 1 type at a time

      saved.push({
        fieldName: e.id,
        operator: 'gte',
        value: [
          {
            searchTerm: this.transformDateToUTCString(e.date![0], 'START') as any
          }
        ]
      });
      saved.push({
        fieldName: e.id,
        operator: 'lte',
        value: [
          {
            searchTerm: this.transformDateToUTCString(e.date![1], 'END') as any
          }
        ]
      });
    });

    // ODs and ADDITIONAL FIELDS -- creates a separated entry grouped by field type.

    const filterFamilies = [FilterType.ADDITIONAL_FIELDS, FilterType.ORIGIN, FilterType.DESTINATION];

    filterFamilies.forEach((family) => {
      if (filters[family].length) {
        const dataGroups = {};

        // group fields data
        filters[family].forEach((e) => {
          if (!dataGroups[e.selectedBox!.id]) {
            dataGroups[e.selectedBox!.id] = [];
          }
          dataGroups[e.selectedBox!.id].push({ searchTerm: e.id, label: e.name });
        });

        // create entry by field and add all grouped selections
        Object.keys(dataGroups).forEach((key) => {
          saved.push({
            fieldName: key,
            value: dataGroups[key]
          });
        });
      }
    });

    // --- Generic fields -- basically all checkbox and 'single autocomplete' items

    const genericItems = [
      FilterType.STATE,
      FilterType.STATUS,
      FilterType.PRIMARY_MODE,
      FilterType.BUSINESS_UNIT,
      FilterType.CARRIERS,
      FilterType.PRODUCT,
      FilterType.PARTNER_ID
    ];

    genericItems.forEach((key: string) => {
      if (filters[key].length) {
        const values = filters[key].map((m) => {
          return { searchTerm: m.id, label: m.name };
        });
        saved.push({
          fieldName: key,
          value: values
        });
      }
    });

    // --- special case for DQ: logic will be handled in Backend

    if (filters[FilterType.DQ_FILTER] && filters[FilterType.DQ_FILTER][0]?.id == 'dq') {
      saved.push({
        fieldName: 'dqFilter',
        value: []
      });
    }

    return saved;
  },

  // transforms a DB filter conditions array into a Frontend usable object

  transformSavedToObject(data) {
    const selectionObj: any = {
      [FilterType.TIME]: [],
      [FilterType.ORIGIN]: [],
      [FilterType.DESTINATION]: [],
      [FilterType.STATE]: [],
      [FilterType.CARRIERS]: [],
      [FilterType.STATUS]: [],
      [FilterType.PARTNER_ID]: [],
      [FilterType.PRIMARY_MODE]: [],
      [FilterType.BUSINESS_UNIT]: [],
      [FilterType.PRODUCT]: [],
      [FilterType.ADDITIONAL_FIELDS]: [],
      [FilterType.DQ_FILTER]: []
    };

    const time = Object.values(FilterTime);
    const Origins = Object.values(FilterOrigins);
    const Destinations = Object.values(FilterDestinations);
    const mainFields = Object.values(FilterType);

    const additionalFields = Object.values(FilterAdditional);

    data.forEach((el) => {
      // ----- TIME
      if (time.includes(el.fieldName)) {
        //
        if (selectionObj[FilterType.TIME].length === 0) {
          // as dates come in 2 separated groups (weird backend stuff) we add the object on the 1st
          selectionObj[FilterType.TIME].push({
            filterId: FilterType.TIME,
            id: el.fieldName,
            name: new Date(el.value[0].searchTerm).toLocaleDateString(),
            selected: false,
            date: [new Date(el.value[0].searchTerm)],
            title: FilterLabels[el.fieldName]
          });
        } else {
          // if an iteration already exists we just add the date to the existing object
          selectionObj[FilterType.TIME][0].name += ' - ' + new Date(el.value[0].searchTerm).toLocaleDateString();
          selectionObj[FilterType.TIME][0].date.push(new Date(el.value[0].searchTerm));
        }
      }

      // ---- ORIGIN DESTINATION
      else if (Destinations.includes(el.fieldName) || Origins.includes(el.fieldName)) {
        const ODmapping = {
          [FilterOrigins.regionOrigin]: FilterType.ORIGIN,
          [FilterOrigins.countryOrigin]: FilterType.ORIGIN,
          [FilterOrigins.companyOrigin]: FilterType.ORIGIN,
          [FilterDestinations.regionDestination]: FilterType.DESTINATION,
          [FilterDestinations.countryDestination]: FilterType.DESTINATION,
          [FilterDestinations.companyDestination]: FilterType.DESTINATION
        };

        for (let i = 0; i < el.value.length; i++) {
          // loop because we may have multiple origins or destinations on the same query, here we classify them into the correct filter family
          selectionObj[ODmapping[el.fieldName]].push({
            filterId: ODmapping[el.fieldName],
            id: el.value[i].searchTerm,
            name: el.value[i].label,
            title: FilterLabels[el.fieldName],
            selectedBox: {
              id: el.fieldName,
              name: FilterLabels[el.fieldName],
              selected: false
            }
          });
        }
      }

      // --- GENERIC GROUPS (CHECKBOXES AND AUTOFILL)
      else if (mainFields.includes(el.fieldName)) {
        for (let i = 0; i < el.value.length; i++) {
          selectionObj[el.fieldName].push({
            filterId: el.fieldName,
            id: el.value[i].searchTerm,
            name: this.getGenericName(el.fieldName, el.value[i]),
            title: FilterLabels[el.fieldName],
            selectedBox: null
          });
        }
      }

      // --- ADDITIONAL FIELDS
      else if (additionalFields.includes(el.fieldName)) {
        for (let i = 0; i < el.value.length; i++) {
          selectionObj[FilterType.ADDITIONAL_FIELDS].push({
            filterId: FilterType.ADDITIONAL_FIELDS,
            id: el.value[i].searchTerm,
            name: el.value[i].label,
            title: FilterLabels[el.fieldName],
            selectedBox: {
              id: el.fieldName,
              name: FilterLabels[el.fieldName],
              selected: false
            }
          });
        }
      } else {
        console.error('Unknown field type found: ' + el.fieldName);
      }
    });

    return selectionObj;
  },

  // return text string if exists in the constants based on the ID of that value
  getGenericName(filter: string, value: { searchTerm: string; label: string }): string {
    if (Conditions[filter] && Conditions[filter].optionsCheckbox) {
      const condObj = Conditions[filter].optionsCheckbox.find((f) => f.id === value.searchTerm);
      if (condObj) {
        return condObj.name;
      }
    }
    // fallback if could not figure out the real name or it is an autofill field
    return value.label;
  },

  // transform a date object to a UTC string at time zero for the start of the day and time 23:59:59 for the end of the day
  transformDateToUTCString(date, type: 'START' | 'END'): string {
    let dateStr = '';
    if (Object.prototype.toString.call(date) === '[object Date]') {
      const offset = new Date().getTimezoneOffset();
      const myDate = Date.parse(date) - offset * 60 * 1000;
      dateStr = new Date(myDate).toISOString();
    } else {
      dateStr = date.replace('Z', '+00:00');
    }
    const datePart = dateStr.split('T')[0];
    const timePart = type === 'START' ? '00:00:00' : '23:59:59';
    return datePart + 'T' + timePart + '.000+00:00';
  }
};
