/* tslint:disable */
/* eslint-disable */
/**
 * Vista User API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.4.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { Alert } from '../../model/user';
// @ts-ignore
import { AlertCondition } from '../../model/user';
// @ts-ignore
import { EnabledStatus } from '../../model/user';
/**
 * AlertApi - axios parameter creator
 * @export
 */
export const AlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the information about a specific alert.
         * @summary Get alert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAlert', 'id', id)
            const localVarPath = `/users/me/alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get conditions for existing alert.
         * @summary Get alert conditions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertSettings: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAlertSettings', 'id', id)
            const localVarPath = `/users/me/alerts/{id}/conditions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of alerts for logged user.
         * @summary List of alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify conditions for existing alert.
         * @summary Modify alert conditions
         * @param {string} id 
         * @param {Array<AlertCondition>} alertCondition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAlertSettings: async (id: string, alertCondition: Array<AlertCondition>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyAlertSettings', 'id', id)
            // verify required parameter 'alertCondition' is not null or undefined
            assertParamExists('modifyAlertSettings', 'alertCondition', alertCondition)
            const localVarPath = `/users/me/alerts/{id}/conditions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertCondition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifies the conditions of an existing alert to the default values.
         * @summary Reset alert conditions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAlert: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetAlert', 'id', id)
            const localVarPath = `/users/me/alerts/{id}/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifies the status of an existing alert.
         * @summary Modify alert status
         * @param {string} id 
         * @param {EnabledStatus} enabledStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatus: async (id: string, enabledStatus: EnabledStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setStatus', 'id', id)
            // verify required parameter 'enabledStatus' is not null or undefined
            assertParamExists('setStatus', 'enabledStatus', enabledStatus)
            const localVarPath = `/users/me/alerts/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enabledStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get default conditions for alerts.
         * @summary Get default conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/alerts/conditions/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertApi - functional programming interface
 * @export
 */
export const AlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the information about a specific alert.
         * @summary Get alert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlert(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlert(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.getAlert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get conditions for existing alert.
         * @summary Get alert conditions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertSettings(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlertCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertSettings(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.getAlertSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of alerts for logged user.
         * @summary List of alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlerts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlerts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.getAlerts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modify conditions for existing alert.
         * @summary Modify alert conditions
         * @param {string} id 
         * @param {Array<AlertCondition>} alertCondition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyAlertSettings(id: string, alertCondition: Array<AlertCondition>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyAlertSettings(id, alertCondition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.modifyAlertSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifies the conditions of an existing alert to the default values.
         * @summary Reset alert conditions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAlert(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetAlert(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.resetAlert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifies the status of an existing alert.
         * @summary Modify alert status
         * @param {string} id 
         * @param {EnabledStatus} enabledStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStatus(id: string, enabledStatus: EnabledStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStatus(id, enabledStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.setStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get default conditions for alerts.
         * @summary Get default conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlertCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertApi.settings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AlertApi - factory interface
 * @export
 */
export const AlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertApiFp(configuration)
    return {
        /**
         * Retrieves the information about a specific alert.
         * @summary Get alert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(id: string, options?: any): AxiosPromise<Alert> {
            return localVarFp.getAlert(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get conditions for existing alert.
         * @summary Get alert conditions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertSettings(id: string, options?: any): AxiosPromise<Array<AlertCondition>> {
            return localVarFp.getAlertSettings(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of alerts for logged user.
         * @summary List of alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(options?: any): AxiosPromise<Array<Alert>> {
            return localVarFp.getAlerts(options).then((request) => request(axios, basePath));
        },
        /**
         * Modify conditions for existing alert.
         * @summary Modify alert conditions
         * @param {string} id 
         * @param {Array<AlertCondition>} alertCondition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAlertSettings(id: string, alertCondition: Array<AlertCondition>, options?: any): AxiosPromise<Alert> {
            return localVarFp.modifyAlertSettings(id, alertCondition, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifies the conditions of an existing alert to the default values.
         * @summary Reset alert conditions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAlert(id: string, options?: any): AxiosPromise<Alert> {
            return localVarFp.resetAlert(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifies the status of an existing alert.
         * @summary Modify alert status
         * @param {string} id 
         * @param {EnabledStatus} enabledStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatus(id: string, enabledStatus: EnabledStatus, options?: any): AxiosPromise<Alert> {
            return localVarFp.setStatus(id, enabledStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Get default conditions for alerts.
         * @summary Get default conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settings(options?: any): AxiosPromise<Array<AlertCondition>> {
            return localVarFp.settings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertApi - object-oriented interface
 * @export
 * @class AlertApi
 * @extends {BaseAPI}
 */
export class AlertApi extends BaseAPI {
    /**
     * Retrieves the information about a specific alert.
     * @summary Get alert
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getAlert(id: string, options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).getAlert(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get conditions for existing alert.
     * @summary Get alert conditions
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getAlertSettings(id: string, options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).getAlertSettings(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of alerts for logged user.
     * @summary List of alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getAlerts(options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).getAlerts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify conditions for existing alert.
     * @summary Modify alert conditions
     * @param {string} id 
     * @param {Array<AlertCondition>} alertCondition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public modifyAlertSettings(id: string, alertCondition: Array<AlertCondition>, options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).modifyAlertSettings(id, alertCondition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifies the conditions of an existing alert to the default values.
     * @summary Reset alert conditions
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public resetAlert(id: string, options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).resetAlert(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifies the status of an existing alert.
     * @summary Modify alert status
     * @param {string} id 
     * @param {EnabledStatus} enabledStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public setStatus(id: string, enabledStatus: EnabledStatus, options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).setStatus(id, enabledStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get default conditions for alerts.
     * @summary Get default conditions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public settings(options?: RawAxiosRequestConfig) {
        return AlertApiFp(this.configuration).settings(options).then((request) => request(this.axios, this.basePath));
    }
}

