import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf3eaa60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation-content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.dialogAlert.header,
    visible: _ctx.dialogAlert.display,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogAlert.display) = $event)),
    modal: true
  }, {
    footer: _withCtx(() => [
      (_ctx.dialogAlert.type == 'alert')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Button, {
              label: "Ok",
              class: "wide",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('hideDialogAlert'))),
              autofocus: ""
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.dialogAlert.type == 'confirm')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Button, {
              label: _ctx.dialogAlert.buttonNo,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogAlert.actionNo())),
              class: "p-secondary wide"
            }, null, 8, ["label"]),
            _createVNode(_component_Button, {
              class: "wide",
              label: _ctx.dialogAlert.buttonYes,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogAlert.actionYes())),
              autofocus: ""
            }, null, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.dialogAlert.icon)
          ? (_openBlock(), _createElementBlock("em", {
              key: 0,
              class: _normalizeClass(`b-icon ${_ctx.dialogAlert.icon}`)
            }, null, 2))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.dialogAlert.text), 1)
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}