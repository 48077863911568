/* tslint:disable */
/* eslint-disable */
/**
 * Vista User API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.4.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { Filter } from '../../model/user';
/**
 * FilterApi - axios parameter creator
 * @export
 */
export const FilterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes an existing filter.
         * @summary Delete filter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilter: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFilter', 'id', id)
            const localVarPath = `/users/me/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the information about a specific filter.
         * @summary Get filter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilter: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFilter', 'id', id)
            const localVarPath = `/users/me/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of filters for logged user.
         * @summary List of filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilters: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifies an existing filter.
         * @summary Modify filter
         * @param {string} id 
         * @param {Filter} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFilter: async (id: string, filter: Filter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyFilter', 'id', id)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('modifyFilter', 'filter', filter)
            const localVarPath = `/users/me/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores a new filter for the logged user.
         * @summary Save new filter
         * @param {Filter} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFilter: async (filter: Filter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('saveFilter', 'filter', filter)
            const localVarPath = `/users/me/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilterApi - functional programming interface
 * @export
 */
export const FilterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilterApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes an existing filter.
         * @summary Delete filter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFilter(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Filter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFilter(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilterApi.deleteFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the information about a specific filter.
         * @summary Get filter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilter(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilter(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilterApi.getFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of filters for logged user.
         * @summary List of filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilters(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Filter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilters(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilterApi.getFilters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifies an existing filter.
         * @summary Modify filter
         * @param {string} id 
         * @param {Filter} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyFilter(id: string, filter: Filter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyFilter(id, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilterApi.modifyFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores a new filter for the logged user.
         * @summary Save new filter
         * @param {Filter} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFilter(filter: Filter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFilter(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilterApi.saveFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FilterApi - factory interface
 * @export
 */
export const FilterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilterApiFp(configuration)
    return {
        /**
         * Deletes an existing filter.
         * @summary Delete filter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilter(id: string, options?: any): AxiosPromise<Array<Filter>> {
            return localVarFp.deleteFilter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the information about a specific filter.
         * @summary Get filter
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilter(id: string, options?: any): AxiosPromise<Filter> {
            return localVarFp.getFilter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of filters for logged user.
         * @summary List of filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilters(options?: any): AxiosPromise<Array<Filter>> {
            return localVarFp.getFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * Modifies an existing filter.
         * @summary Modify filter
         * @param {string} id 
         * @param {Filter} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFilter(id: string, filter: Filter, options?: any): AxiosPromise<Filter> {
            return localVarFp.modifyFilter(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores a new filter for the logged user.
         * @summary Save new filter
         * @param {Filter} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFilter(filter: Filter, options?: any): AxiosPromise<Filter> {
            return localVarFp.saveFilter(filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilterApi - object-oriented interface
 * @export
 * @class FilterApi
 * @extends {BaseAPI}
 */
export class FilterApi extends BaseAPI {
    /**
     * Deletes an existing filter.
     * @summary Delete filter
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public deleteFilter(id: string, options?: RawAxiosRequestConfig) {
        return FilterApiFp(this.configuration).deleteFilter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the information about a specific filter.
     * @summary Get filter
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public getFilter(id: string, options?: RawAxiosRequestConfig) {
        return FilterApiFp(this.configuration).getFilter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of filters for logged user.
     * @summary List of filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public getFilters(options?: RawAxiosRequestConfig) {
        return FilterApiFp(this.configuration).getFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifies an existing filter.
     * @summary Modify filter
     * @param {string} id 
     * @param {Filter} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public modifyFilter(id: string, filter: Filter, options?: RawAxiosRequestConfig) {
        return FilterApiFp(this.configuration).modifyFilter(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores a new filter for the logged user.
     * @summary Save new filter
     * @param {Filter} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public saveFilter(filter: Filter, options?: RawAxiosRequestConfig) {
        return FilterApiFp(this.configuration).saveFilter(filter, options).then((request) => request(this.axios, this.basePath));
    }
}

