
import { defineComponent } from 'vue';
import { FilterSelection } from '@/typings';
import { FilterType } from '@/typings/enums';
import FiltersUtils from '@/scripts/filtersUtils';

export default defineComponent({
  name: 'Header',
  data() {
    return {
      scrollpx: 0,
      isLogged: this.$store.state.authorized
    };
  },
  computed: {
    selectedConditions(): Record<FilterType, FilterSelection[]> {
      return this.$store.state.filters.selectedConditions;
    },
    userName(): string {
      return this.$store.state.userData ? this.$store.state.userData.fullName : '';
    },
    currentTheme(): any {
      // TODO: implement responsive logo
      return require('@/assets/logo-' + this.$store.state.colorTheme + '.svg');
    },
    isDqFiltering(): boolean {
      return !!this.$store.state.filters.selectedConditions[FilterType.DQ_FILTER]?.length;
    },
    dqTeamAuthorized(): boolean {
      return this.$store.getters.isDQTeamAuthorized;
    }
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
    logout() {
      this.$store.dispatch('LOGOUT');
      this.$router.push('/');
    },
    toggleDqFilter() {
      if (!this.isDqFiltering) {
        this.selectedConditions[FilterType.DQ_FILTER] = [
          {
            id: 'dq',
            name: 'DQ-Filter',
            title: '',
            filterId: FilterType.DQ_FILTER
          }
        ];
      } else {
        this.selectedConditions[FilterType.DQ_FILTER] = [];
      }

      let toCheckConditions = FiltersUtils.createSaveObject(this.selectedConditions);

      // create params object to send to api
      const sort = this.$store.state.shipments.sort;
      const data = {
        page: this.$store.state.shipments.page,
        size: this.$store.state.shipments.pageSize,
        sort: sort.field && sort.order ? `${sort.sortStr},${sort.order}` : '',
        conditions: toCheckConditions
      };

      this.$store.dispatch('shipments/FETCH_SHIPMENTS', data);
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  }
});
