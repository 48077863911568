
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GenericDialog',
  data() {
    return {
      scrollpx: 0
    };
  },
  computed: {
    dialogAlert() {
      return this.$store.state.dialogAlert;
    }
  }
});
