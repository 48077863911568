/* tslint:disable */
/* eslint-disable */
/**
 * Vista User API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.5.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../base';
// @ts-ignore
import { TokenLoggedOutResult } from '../../model/vista';
/**
 * LogoutApi - axios parameter creator
 * @export
 */
export const LogoutApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Logout and revoke a token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      const localVarPath = `/users/me/token/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Return either true or false.
     * @summary Check if the authorization token is logged out.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      const localVarPath = `/users/me/token/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication security_auth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'security_auth', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * LogoutApi - functional programming interface
 * @export
 */
export const LogoutApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LogoutApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Logout and revoke a token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['LogoutApi.logout']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return either true or false.
     * @summary Check if the authorization token is logged out.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validToken(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenLoggedOutResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validToken(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['LogoutApi.validToken']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
    }
  };
};

/**
 * LogoutApi - factory interface
 * @export
 */
export const LogoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LogoutApiFp(configuration);
  return {
    /**
     *
     * @summary Logout and revoke a token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options?: any): AxiosPromise<void> {
      return localVarFp.logout(options).then((request) => request(axios, basePath));
    },
    /**
     * Return either true or false.
     * @summary Check if the authorization token is logged out.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validToken(options?: any): AxiosPromise<TokenLoggedOutResult> {
      return localVarFp.validToken(options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * LogoutApi - object-oriented interface
 * @export
 * @class LogoutApi
 * @extends {BaseAPI}
 */
export class LogoutApi extends BaseAPI {
  /**
   *
   * @summary Logout and revoke a token.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogoutApi
   */
  public logout(options?: RawAxiosRequestConfig) {
    return LogoutApiFp(this.configuration)
      .logout(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return either true or false.
   * @summary Check if the authorization token is logged out.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogoutApi
   */
  public validToken(options?: RawAxiosRequestConfig) {
    return LogoutApiFp(this.configuration)
      .validToken(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
