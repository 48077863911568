import { computed, inject } from 'vue';
import { storeKey } from 'vuex';
import { State } from '@/store';
import { User } from '@/typings';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const timezonePostfix = ' (Browser default)';
const defaultFormat = 'DD/MM/YYYY';

const Filters = {
  toLocalDate(dateString: string, includeTime: boolean) {
    if (!dateString || new Date(dateString).getTime() <= 0) {
      return 'Not found';
    }
    let result: string | null = null;
    const timeFormat = ' HH:mm';
    const userData: User = useSelector((state) => state.userData).value as User;
    const applyFormat = getDateFormat(userData, includeTime);
    if (userData.settings && userData.settings.userLocale) {
      const timezone = retrieveOnlyTimezone(userData.settings.userLocale.timezone.name);
      result = applyTimezone(dateString, timezone, applyFormat);
    } else {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      result = applyTimezone(dateString, timezone, defaultFormat + timeFormat);
    }
    return result;
  },

  applyOnlyDateTimeFormat(dateString: string, includeTime: boolean, isEditable: boolean | undefined) {
    if (isEditable) {
      //Overriding the format
      const format = defaultFormat + ' HH:mm';
      return dayjs.utc(dateString).format(format);
    } else {
      const userData: User = useSelector((state) => state.userData).value as User;
      const applyFormat = getDateFormat(userData, includeTime);
      return dayjs(dateString).format(applyFormat);
    }
  },

  validate(value: string) {
    if (!value) {
      return false;
    }
    const format = defaultFormat + ' HH:mm';
    const parse = dayjs.utc(value, format, true);
    return parse.isValid();
  }
};

const getDateFormat = (userData: User, includeTime: boolean) => {
  const timeFormat = ' HH:mm';
  let dateFormat = includeTime ? defaultFormat + timeFormat : defaultFormat;
  if (userData.settings && userData.settings.userLocale) {
    if (includeTime) {
      dateFormat = userData.settings.userLocale.dateFormat + timeFormat;
    } else {
      dateFormat = userData.settings.userLocale.dateFormat;
    }
  }
  return dateFormat;
};

const applyTimezone = (dateString: string, timezone: string, format: string) => {
  const localDate = dayjs(dateString);
  return localDate.tz(timezone).format(format);
};

const retrieveOnlyTimezone = (name: string) => {
  const index = name.indexOf(timezonePostfix);
  if (index != -1) {
    return name.substr(0, index);
  }
  return name;
};

export const useSelector = <LocalState extends State = State>(fn: (state: LocalState) => LocalState[keyof LocalState]) => {
  const rootStore = inject(storeKey) as { state: State };
  return computed(() => fn(rootStore.state as LocalState));
};

export default Filters;
