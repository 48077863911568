//TODO: replace all text with i18n keys when enabling multilanguage

import {
  basfRegionApi,
  deliveriesApi,
  partnersApi,
  productsApi,
  purchaseOrdersApi,
  referencesApi,
  sBusApi,
  salesOrdersApi,
  shipmentsApi
} from '@/services/api';
import { Pageable } from '@/services/model/vista';
import { FilterType, FilterTime, FilterOrigins, FilterDestinations, FilterAdditional } from '@/typings/enums';
import { AxiosResponse } from 'axios';

export const globalDateTimeFormat = 'DD/MM/YYYY HH:mm';
export const globalDateFormat = 'DD/MM/YYYY';

export const FooterOptions = [
  {
    name: 'Intranet',
    to: '/intranet'
  },
  {
    name: 'Contact',
    to: '/contact'
  }
];

// filter conditions text mapping
export const FilterLabels: { [key: string]: string } = {
  [FilterTime.actualDelivery]: 'ATA POD',
  [FilterTime.shimpentStart]: 'Shipment start',
  [FilterTime.carrierETA]: 'ETA POD',
  [FilterTime.calculatedETA]: 'Calculated ETA',
  [FilterTime.promisedBy]: 'Confirmed Delivery Date',
  [FilterType.ORIGIN]: 'Origin Plant',
  [FilterType.DESTINATION]: 'Ship To Destination',
  [FilterType.STATE]: 'State',
  [FilterType.CARRIERS]: 'Carriers',
  [FilterType.STATUS]: 'Lateness',
  [FilterType.PARTNER_ID]: 'Partner',
  [FilterType.PRIMARY_MODE]: 'Primary Mode',
  [FilterType.BUSINESS_UNIT]: 'Business Unit',
  [FilterType.PRODUCT]: 'Product',
  [FilterOrigins.companyOrigin]: 'Company',
  [FilterOrigins.regionOrigin]: 'Region',
  [FilterOrigins.countryOrigin]: 'Country',
  [FilterDestinations.companyDestination]: 'Company',
  [FilterDestinations.regionDestination]: 'Region',
  [FilterDestinations.countryDestination]: 'Country',
  [FilterAdditional.shipmentId]: 'Shipment',
  [FilterAdditional.billOfLanding]: 'Bill of Lading',
  [FilterAdditional.poNumber]: 'Purchase Order',
  [FilterAdditional.soNumber]: 'Sales Order',
  [FilterAdditional.deliverID]: 'Delivery',
  [FilterAdditional.houseAirwBill]: 'House Airway Bill',
  [FilterAdditional.masterAirwBill]: 'Master Airway Bill',
  [FilterAdditional.containerNO]: 'HU Identification',
  [FilterAdditional.vesselNO]: 'Vessel IMO',
  [FilterAdditional.vesselName]: 'Vessel Name',
  [FilterAdditional.voyageId]: 'Voyage ID',
  [FilterAdditional.bookingNo]: 'Booking Number',
  [FilterAdditional.flightNO]: 'Flight No',
  [FilterAdditional.incoterms1]: 'Incoterm 1',
  [FilterAdditional.incoterms2]: 'Incoterm 2'
};

export const Conditions = {
  [FilterType.TIME]: {
    name: 'Time',
    options: [
      { id: 'noDate', name: 'No date', selected: true },
      {
        id: FilterTime.actualDelivery,
        name: FilterLabels[FilterTime.actualDelivery],
        selected: false
      },
      {
        id: FilterTime.shimpentStart,
        name: FilterLabels[FilterTime.shimpentStart],
        selected: false
      },
      {
        id: FilterTime.carrierETA,
        name: FilterLabels[FilterTime.carrierETA],
        selected: false
      },
      {
        id: FilterTime.promisedBy,
        name: FilterLabels[FilterTime.promisedBy],
        selected: false
      }
    ],
    isDate: true,
    date: []
  },
  [FilterType.ORIGIN]: {
    name: 'Origin Plant',
    options: [
      { id: FilterOrigins.companyOrigin, name: FilterLabels[FilterOrigins.companyOrigin], selected: true },
      { id: FilterOrigins.regionOrigin, name: FilterLabels[FilterOrigins.regionOrigin], selected: false },
      {
        id: FilterOrigins.countryOrigin,
        name: FilterLabels[FilterOrigins.countryOrigin],
        selected: false
      }
    ],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.DESTINATION]: {
    name: 'Ship To Destination',
    options: [
      { id: FilterDestinations.companyDestination, name: FilterLabels[FilterDestinations.companyDestination], selected: true },
      { id: FilterDestinations.regionDestination, name: FilterLabels[FilterDestinations.regionDestination], selected: false },
      {
        id: FilterDestinations.countryDestination,
        name: FilterLabels[FilterDestinations.countryDestination],
        selected: false
      }
    ],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.STATE]: {
    name: FilterLabels[FilterType.STATE],
    optionsCheckbox: [
      { id: 'BOOKED', name: 'Booked' },
      { id: 'IN_TRANSIT', name: 'In Transit' },
      { id: 'DELIVERED', name: 'Delivered ' },
      { id: 'CLOSED', name: 'Closed ' }
    ],
    optionsSelected: []
  },
  [FilterType.STATUS]: {
    name: FilterLabels[FilterType.STATUS],
    optionsCheckbox: [
      { id: 'EARLY', name: 'Early' },
      { id: 'ON_TIME', name: 'On time' },
      { id: 'LATE', name: 'Late' }
    ],
    optionsSelected: []
  },
  [FilterType.CARRIERS]: {
    name: FilterLabels[FilterType.CARRIERS],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.PARTNER_ID]: {
    name: FilterLabels[FilterType.PARTNER_ID],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.PRIMARY_MODE]: {
    name: FilterLabels[FilterType.PRIMARY_MODE],
    optionsCheckbox: [
      { id: 'AIR', name: 'Air' },
      { id: 'SEA', name: 'Sea' },
      { id: 'SURFACE', name: 'Surface' }
    ],
    optionsSelected: []
  },
  [FilterType.PRODUCT]: {
    name: FilterLabels[FilterType.PRODUCT],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.BUSINESS_UNIT]: {
    name: FilterLabels[FilterType.BUSINESS_UNIT],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.ADDITIONAL_FIELDS]: {
    name: 'Additional Fields',
    options: [
      {
        id: FilterAdditional.shipmentId,
        name: FilterLabels[FilterAdditional.shipmentId],
        selected: true
      },
      {
        id: FilterAdditional.billOfLanding,
        name: FilterLabels[FilterAdditional.billOfLanding],
        selected: false
      },
      {
        id: FilterAdditional.poNumber,
        name: FilterLabels[FilterAdditional.poNumber],
        selected: false
      },
      {
        id: FilterAdditional.soNumber,
        name: FilterLabels[FilterAdditional.soNumber],
        selected: false
      },
      {
        id: FilterAdditional.deliverID,
        name: FilterLabels[FilterAdditional.deliverID],
        selected: false
      },
      {
        id: FilterAdditional.houseAirwBill,
        name: FilterLabels[FilterAdditional.houseAirwBill],
        selected: false
      },
      {
        id: FilterAdditional.masterAirwBill,
        name: FilterLabels[FilterAdditional.masterAirwBill],
        selected: false
      },
      {
        id: FilterAdditional.containerNO,
        name: FilterLabels[FilterAdditional.containerNO],
        selected: false
      },
      {
        id: FilterAdditional.vesselNO,
        name: FilterLabels[FilterAdditional.vesselNO],
        selected: false
      },
      {
        id: FilterAdditional.vesselName,
        name: FilterLabels[FilterAdditional.vesselName],
        selected: false
      },
      {
        id: FilterAdditional.voyageId,
        name: FilterLabels[FilterAdditional.voyageId],
        selected: false
      },
      {
        id: FilterAdditional.bookingNo,
        name: FilterLabels[FilterAdditional.bookingNo],
        selected: false
      },
      {
        id: FilterAdditional.flightNO,
        name: FilterLabels[FilterAdditional.flightNO],
        selected: false
      },
      {
        id: FilterAdditional.incoterms1,
        name: FilterLabels[FilterAdditional.incoterms1],
        selected: false
      },
      {
        id: FilterAdditional.incoterms2,
        name: FilterLabels[FilterAdditional.incoterms2],
        selected: false
      }
    ],
    isSearchable: true,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  },
  [FilterType.DQ_FILTER]: {
    name: FilterLabels[FilterType.DQ_FILTER],
    isSearchable: false,
    searchField: '',
    suggestions: [],
    optionsSelected: []
  }
};

// Mapping for the autocomplete endpoints, as each endpoint is different and responses do not match (yes... crazy!), the component will relay in this values
//    url: api endpoint
//    attribute: attibute that contains the main text on each list element
//    filterAttribute: attribute that contains the value that we want to send to the filter api ( filter value),
//    detail: attribute that contains the detail value (subtitle en each entry),
//    detailLabel: Text label for the subtitle.

const autocompletePageable: Pageable = {
  page: 0,
  size: 100
};

type Response<ResultType> = Promise<AxiosResponse<ResultType[], any>>;

export const AutoCompleteEndpoints: Record<
  string,
  {
    request: (search: string) => Response<any>;
    attribute: string;
    filterAttribute: string;
    detail: string | null;
    detailLabel: string | null;
  }
> = {
  [FilterOrigins.companyOrigin]: {
    request: (search: string) => referencesApi.originPlants(search, autocompletePageable),
    attribute: 'name',
    filterAttribute: '_id',
    detail: '_id',
    detailLabel: 'ID'
  },
  [FilterOrigins.regionOrigin]: {
    request: (search: string) => basfRegionApi.regions(search, autocompletePageable),
    attribute: 'name',
    filterAttribute: '_id',
    detail: '_id',
    detailLabel: 'ID'
  },
  [FilterOrigins.countryOrigin]: {
    request: (search: string) => basfRegionApi.countries(search, autocompletePageable),
    attribute: 'name',
    filterAttribute: '_id',
    detail: '_id',
    detailLabel: 'ID'
  },
  [FilterDestinations.companyDestination]: {
    request: (search: string) => partnersApi.destination(search, autocompletePageable),
    attribute: 'name',
    filterAttribute: '_id',
    detail: '_id',
    detailLabel: 'ID'
  },
  [FilterDestinations.regionDestination]: {
    request: (search: string) => basfRegionApi.regions(search, autocompletePageable),
    attribute: 'name',
    filterAttribute: '_id',
    detail: '_id',
    detailLabel: 'ID'
  },
  [FilterDestinations.countryDestination]: {
    request: (search: string) => basfRegionApi.countries(search, autocompletePageable),
    attribute: 'name',
    filterAttribute: '_id',
    detail: '_id',
    detailLabel: 'ID'
  },
  [FilterType.CARRIERS]: {
    request: (search: string) => partnersApi.carriers(search, autocompletePageable),
    attribute: 'partnerName',
    filterAttribute: 'partnerUid',
    detail: 'partnerUid',
    detailLabel: 'ID'
  },
  [FilterType.PARTNER_ID]: {
    request: (search: string) => partnersApi.partners(search, autocompletePageable),
    attribute: 'partnerName',
    filterAttribute: 'partnerUid',
    detail: 'partnerUid',
    detailLabel: 'ID'
  },
  [FilterType.PRODUCT]: {
    request: (search: string) => productsApi.getProducts(search, autocompletePageable),
    attribute: 'label',
    filterAttribute: 'value',
    detail: 'value',
    detailLabel: 'Material Number'
  },
  [FilterType.BUSINESS_UNIT]: {
    request: (search: string) => sBusApi.getBusinessUnits(search, autocompletePageable),
    attribute: 'label',
    filterAttribute: 'value',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.shipmentId]: {
    request: (search: string) => shipmentsApi.shipmentNumbers(search, autocompletePageable),
    attribute: '_id',
    filterAttribute: '_id',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.billOfLanding]: {
    request: (search: string) => deliveriesApi.billOfLadings(search, autocompletePageable),
    attribute: 'billOfLadingNumber',
    filterAttribute: 'billOfLadingNumber',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.poNumber]: {
    request: (search: string) => purchaseOrdersApi.getPurchaseOrderNumbers(search, autocompletePageable),
    attribute: '_id',
    filterAttribute: '_id',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.soNumber]: {
    request: (search: string) => salesOrdersApi.getOrderNumbers(search, autocompletePageable),
    attribute: '_id',
    filterAttribute: '_id',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.deliverID]: {
    request: (search: string) => deliveriesApi.deliveryNumbers(search, autocompletePageable),
    attribute: '_id',
    filterAttribute: '_id',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.houseAirwBill]: {
    request: (search: string) => deliveriesApi.houseAirWayBills(search, autocompletePageable),
    attribute: 'airwayBillNumber',
    filterAttribute: 'airwayBillNumber',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.masterAirwBill]: {
    request: (search: string) => deliveriesApi.masterAirWaybills(search, autocompletePageable),
    attribute: 'masterAirWaybill',
    filterAttribute: 'masterAirWaybill',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.containerNO]: {
    request: (search: string) => shipmentsApi.containerNumbers(search, autocompletePageable),
    attribute: 'containerNumber',
    filterAttribute: 'containerNumber',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.vesselNO]: {
    request: (search: string) => shipmentsApi.vesselsByIMO(search, autocompletePageable),
    filterAttribute: 'imo',
    attribute: 'name',
    detail: 'imo',
    detailLabel: 'IMO'
  },
  [FilterAdditional.vesselName]: {
    request: (search: string) => shipmentsApi.vesselsByNames(search, autocompletePageable),
    filterAttribute: 'imo',
    attribute: 'name',
    detail: 'imo',
    detailLabel: 'IMO'
  },
  [FilterAdditional.voyageId]: {
    request: (search: string) => shipmentsApi.getVoyageIds(search, autocompletePageable),
    attribute: 'voyageId',
    filterAttribute: 'voyageId',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.bookingNo]: {
    request: (search: string) => shipmentsApi.bookingNumbers(search, autocompletePageable),
    attribute: 'bookingNumber',
    filterAttribute: 'bookingNumber',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.flightNO]: {
    request: (search: string) => deliveriesApi.flightNumbers(search, autocompletePageable),
    attribute: 'flightNumber',
    filterAttribute: 'flightNumber',
    detail: null,
    detailLabel: null
  },
  [FilterAdditional.incoterms1]: {
    request: (search: string) => deliveriesApi.incotermByCodes(search, autocompletePageable),
    attribute: 'code',
    filterAttribute: 'code',
    detail: 'location',
    detailLabel: 'Location'
  },
  [FilterAdditional.incoterms2]: {
    request: (search: string) => deliveriesApi.incotermByLocations(search, autocompletePageable),
    attribute: 'location',
    filterAttribute: 'location',
    detail: 'code',
    detailLabel: 'Code'
  }
};

// TODO: replace text with i18n key
export const ConditionTypes = {
  HOURS: {
    text: 'Hour',
    max: 8760
  },
  DAYS: {
    text: 'Day',
    max: 365
  },
  WEEKS: {
    text: 'Week',
    max: 52
  },
  MONTHS: {
    text: 'Month',
    max: 12
  }
};
