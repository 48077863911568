
import { defineComponent } from 'vue';
import { FooterOptions } from '@/constants';

export default defineComponent({
  name: 'Home',
  emits: ['getAuthCode'],
  data() {
    return {
      footerOptions: FooterOptions
    };
  },
  computed: {
    bkgId(): any {
      return this.$store.getters.getBackgroundID;
    }
  },
  methods: {
    login() {
      this.$store.commit('setLoading', true);
      this.$emit('getAuthCode');
    },
    requestAccess() {
      // TODO: link to the proper location
      this.$store.commit('showDialogAlert', {
        text: 'AccessIT implementation in progress. Coming soon',
        header: 'Request access',
        icon: null
      });
    },
    currentTheme(): string {
      return require('@/assets/logo-' + this.$store.state.colorTheme + '.svg');
    },
    sessionExpired() {
      this.$store.commit('showDialogAlert', {
        text: 'Your session has expired due to your inactivity. No worry, simply login again.',
        header: 'Your session has expired',
        icon: null
      });
    }
  }
});
